import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { ReactComponent as Logo } from './svg/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

export default class Component extends PureComponent {
  render() {
    return (
      <Landing>
        <Logo width={400} height={400} />
        <Copy>
          © {new Date().getFullYear()} Radiant Forge. All Rights Reserved.
        </Copy>
        <Discord>
          <FontAwesomeIcon icon={faDiscord} />
          <div>
            Join our Discord community.{' '}
            <a
              href="https://discord.gg/FAmRekUZre"
              rel="noopener noreferrer"
              target="_blank"
            >
              Join Here!
            </a>
          </div>
        </Discord>
      </Landing>
    );
  }
}

const Landing = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
  min-height: min-content;
`;

const Copy = styled.div`
  font-size: 16px;
  font-weight: 500;
  opacity: 0.5;
`;

const Discord = styled.div`
  box-sizing: border-box;
  padding: 12px 20px;
  background: #5a65ea;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  gap: 10px;

  svg {
    font-size: 20px;
  }

  a {
    font-weight: 500;
    cursor: pointer;
    opacity: 0.5;
    color: inherit;
    text-decoration: none;
    transition: 0.5s;

    &:hover {
      opacity: 0.75;
    }
  }
`;
